import React, { Component, Fragment } from "react";
import $ from 'jquery'
import '../assets/css/font-awesome.min.css';
import '../assets/fonts/fonts.css';
import './Custom.css';
import LatestNews from './LatestNews';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import postdisclaimerIcon from '../assets/images/postdisclamier-icon.svg'
import externalLinkIcon from '../assets/images/externallink.svg'
import ReleaseSoon from './ReleasingSoon'
import YouMayAlsoLike from './YouMayAlsoLike'
import axios from 'axios';
import * as APICONST from '../constants/Api';
import * as COMMONCONST from '../constants/Common';
import NotFound404 from './NotFound404';
import PopularList from './PopularList';
// import SneakerLogo from '../assets/images/SN-logo.svg'
import moment from 'moment-timezone';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PinchToZoom from 'react-pinch-and-zoom';
let sizerun;
class ProductDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            productDetail: {
                simillar: '',
                you_may_like: '',
                images: [],
                sizes: [],
                images_captions: [],
                zone_wises: {},
                releases: [],
                product_update_messages: []
            },
            showtimer: '',
            timeZone: [],
            isLoading: false,
            isError: false,
            userTimeZone: '',
            width: window.innerWidth,
            height: window.innerHeight,

        };

        this.getProductDetails = this.getProductDetails.bind(this);
        this.getTimeZoneList = this.getTimeZoneList.bind(this);
        this.like = this.like.bind(this);
        this.addKfRetailarsCount = this.addKfRetailarsCount.bind(this);
        this.watchlist = this.watchlist.bind(this);
        this.startCountDown = this.startCountDown.bind(this);
        this.countDown = this.countDown.bind(this);
        this.updateTimezone = this.updateTimezone.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handlePinch = this.handlePinch.bind(this);
        this.getInfoColumnHeight = this.getInfoColumnHeight.bind(this);
    }

    componentDidMount() {
        // this.getProductDetails();
        this.setState({ productDetail: this.props.product });
        this.getTimeZoneList();
        this.updateWindowDimensions();
        this.copySku();
        this.getInfoColumnHeight();
        window.addEventListener('resize', this.updateWindowDimensions);
        COMMONCONST.GAPageView();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    handlePinch() {
        console.log("cb");
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    addKfRetailarsCount(id) {
        axios({
            method: "get",
            url: APICONST.KFRetailersCount(id),
            headers: { "content-type": "application/json" }
        })
            .then(response => {
            })
            .catch(error => {
            });
    }

    componentWillReceiveProps(nextProps) {
        // this.setState({
        //     productDetail: {
        //         simillar: '',
        //         you_may_like: '',
        //         images: [],
        //         sizes: [],
        //         images_captions: [],
        //         zone_wises: {},
        //         releases: []
        //     },
        //     showtimer: '',
        //     timeZone: [],
        //     isLoading: false,
        //     isError: false,
        //     userTimeZone: ''
        // }, () => {
        //     this.getProductDetails(nextProps);
        //     this.getTimeZoneList();

        // });
        this.getTimeZoneList();
    }

    updateTimezone = e => {
        // this.setState({ userTimeZone: e.target.value }, () => {
        //     let tempProductDetails = this.state.productDetail;
        //     let isChange = false;
        //     Object.keys(tempProductDetails.zone_wises).forEach((key) => {
        //         isChange = true;
        //         tempProductDetails.zone_wises[key].forEach((k, i) => {
        //             if (k.time) {
        //                 let def = moment.tz(k.release_date, k.default_time_zone);
        //                 let cuu = def.clone().tz(this.state.userTimeZone);
        //                 if (this.state.userTimeZone === k.default_time_zone) {
        //                     tempProductDetails.zone_wises[key][i]['time'] = def.format("mm") !== '00' ? def.format("h:mma z") : def.format("ha z");
        //                 } else {
        //                     tempProductDetails.zone_wises[key][i]['time'] = (def.format("mm") !== '00' ? def.format("h:mma z") : def.format("ha z")) + ' / ' + (cuu.format("mm") !== '00' ? cuu.format("h:mma z") : cuu.format("ha z"));
        //                 }
        //             }
        //         })
        //     })
        //     if (isChange) {
        //         this.setState({ productDetail: tempProductDetails });
        //     }
        // })
        this.setState({ userTimeZone: e.target.value }, () => {
            let tempProductDetails = this.state.productDetail;
            let isChange = false;
            tempProductDetails.sizes.forEach((value, key) => {
                isChange = true;
                value.product_regions_rereleases.forEach((k1, i1) => {
                    k1.product_stockists.forEach((k, i) => {
                        if (k.time) {
                            let def = moment.tz(k.release_date, k.default_time_zone);
                            let cuu = def.clone().tz(this.state.userTimeZone);
                            if (this.state.userTimeZone === k.default_time_zone) {
                                tempProductDetails.sizes[key]['product_regions_rereleases'][i1]['product_stockists'][i]['time'] = def.format("mm") !== '00' ? def.format("h:mma z") : def.format("ha z");
                            } else {
                                tempProductDetails.sizes[key]['product_regions_rereleases'][i1]['product_stockists'][i]['time'] = (def.format("mm") !== '00' ? def.format("h:mma z") : def.format("ha z")) + ' / ' + (cuu.format("mm") !== '00' ? cuu.format("h:mma z") : cuu.format("ha z"));
                            }
                        }
                    })
                })
            })
            if (isChange) {
                this.setState({ productDetail: tempProductDetails });
            }
        })
    }

    googleCalendar(title, date, details, timezone) {
        let rdate = moment.tz(date, timezone);
        let utcDate = rdate.clone().tz('UTC');

        // return 'https://www.google.com/calendar/render?action=TEMPLATE&text='.rawurlencode($post_values -> post_title). '&dates='.rawurlencode($current). '/'.rawurlencode($current). '&details='.esc_url($release_post_product_url). '&sf=true&output=xml';
        let d = utcDate.format("YYYYMMDD") + 'T' + utcDate.format("HHmmss") + 'Z';
        let url = 'https://www.google.com/calendar/render?action=TEMPLATE&text=' + title + '&dates=' + d + '/' + d + '&details=' + details + '&sf=true&output=xml';
        window.open(url, '_blank');
    }

    ical_download(title, date, details, timezone) {
        let rdate = moment.tz(date, timezone);
        let utcDate = rdate.clone().tz('UTC');
        let finaldate = utcDate.format("YYYYMMDD") + 'T' + utcDate.format("HHmmss") + 'Z';

        let current = moment().tz('UTC');
        let created = current.format("YYYYMMDD") + 'T' + current.format("HHmmss") + 'Z';


        //name of file to download as
        this.fileName = title + '.ics';

        this._save = function (fileURL) {
            if (!window.ActiveXObject) {
                var save = document.createElement('a');
                save.href = fileURL;
                save.target = '_blank';
                save.download = this.fileName || 'unknown';

                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);

                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }

            // for IE < 11
            else if (!!window.ActiveXObject && document.execCommand) {
                var _window = window.open(fileURL, '_blank');
                _window.document.close();
                _window.document.execCommand('SaveAs', true, this.fileName || fileURL)
                _window.close();
            }
        }

        var ics_lines = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "PRODID:-//hacksw/handcal//NONSGML v1.0//EN",
            "CALSCALE:GREGORIAN",
            "BEGIN:VEVENT",
            "LOCATION:",
            "DESCRIPTION:",
            "DTSTART:" + finaldate,
            "DTEND:" + finaldate,
            "SUMMARY:" + title,
            "URL;VALUE=URI:" + details,
            "BEGIN:VALARM",
            "ACTION:DISPLAY",
            "TRIGGER;VALUE=DATE-TIME:" + finaldate,
            "END:VALARM",
            "DTSTAMP:" + created,
            "UID:5ee083ee922bf",
            "END:VEVENT",
            "END:VCALENDAR",
        ]

        var dlurl = 'data:text/calendar;base64,' + btoa(ics_lines.join('\r\n'));

        try {
            this._save(dlurl);
        } catch (e) {
        }



    }


    iCalendar(title, date, details, timezone) {
        this.ical_download(title, date, details, timezone);
    }

    getTimeZoneList() {
        axios({
            method: "get",
            url: APICONST.TIMEZONELIST,
            headers: { "content-type": "application/json" }
        })
            .then(response => {

                this.setState({
                    timeZone: response.data.result.timeZoneList
                });

            })
            .catch(error => { });
    }

    like(id) {
        axios({
            method: "get",
            url: APICONST.LIKES(id),
            headers: { "content-type": "application/json" }
        })
            .then(response => {
                if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
                    $('#' + id + '-like').text(parseInt($('#' + id + '-like').text()) + 1);
                    $('#' + id + '-like').parent('li').addClass('active');
                    $('.btn-primary-gray-like').addClass('active');
                    $('.btn-primary-gray-like-text').text('Liked');

                } else {
                    $('#' + id + '-like').text(parseInt($('#' + id + '-like').text()) - 1);
                    $('#' + id + '-like').parent('li').removeClass('active');
                    $('.btn-primary-gray-like').removeClass('active');
                    $('.btn-primary-gray-like-text').text('Like');
                }
            })
            .catch(error => {
            });
    }

    watchlist(id) {
        COMMONCONST.setCookie('watchlist', true);
        axios({
            method: "get",
            url: APICONST.WATCHLIST(id),
            headers: { "content-type": "application/json" },
            params: { 'watchlist-cookie': COMMONCONST.getCookie('watchlist') }
        })
            .then(response => {
                if (typeof (response.data.result.data) !== undefined && response.data.result.data === 1) {
                    $('#' + id + '-watchlist').text(parseInt($('#' + id + '-watchlist').text()) + response.data.result.add);
                    $('#' + id + '-watchlist').parent('li').addClass('active');
                    $('.btn-primary-gray-watchlist').addClass('active');
                    $('.btn-primary-gray-watchlist-text').text('Added to Watchlist');
                } else {
                    $('#' + id + '-watchlist').parent('li').removeClass('active');
                    $('.btn-primary-gray-watchlist').removeClass('active');
                    $('.btn-primary-gray-watchlist-text').text('Add to Watchlist');
                }
            })
            .catch(error => {
            });
    }

    checkReleaseDate(date, timezone) {
        var then = moment.tz(date, timezone);
        // (then.format("YYYY-MM-DD HH:mm:ss"));
        // .format('z')
        var current = moment().tz(timezone);
        if (then > current) {
            return true;
        } else {
            return false;
        }
    }

    getProductDetails(props = false) {
        let slug;
        if (props) {
            slug = props.slug;
        } else {
            slug = this.props.slug;
        }
        this.setState({
            isLoading: true
        })
        axios({
            method: "get",
            url: APICONST.PRODUCTDETAILS(slug),
            headers: { "content-type": "application/json" },
            params: {
                cookie: COMMONCONST.getCookie('watchlist')
            }
        })
            .then(response => {
                COMMONCONST.GAPageView();
                this.setState({
                    isLoading: false,
                    productDetail: response.data.result.product
                }, () => {
                    this.startCountDown();
                    this.setState({ userTimeZone: this.state.productDetail.current_time_zone })

                });

            })
            .catch(error => this.setState({ isError: true, isLoading: false }));

    }

    countDown() {
        var then = moment.tz(this.state.productDetail.current_release, this.state.productDetail.current_time_zone);
        var current = moment().tz(this.state.productDetail.current_time_zone);
        if (then > current) {
            let days = then.diff(current, 'days');
            let hours = then.diff(current, 'hours') % 24;
            let minutes = then.diff(current, 'minutes') % 60;
            let show = '';

            if (minutes <= 0) {
                clearInterval(this.myInterval)
            } else {
                show = days + 'd ' + hours + 'h ' + minutes + 'm';
            }
            this.setState({
                showtimer: show
            })
        }
    }

    closeImageScrollar() {
        $("body").removeClass("img-scrollar-active-body");
        $(".img-scrollar-popup").removeClass("img-scrollar-active");
        $(".img-scrollar-content .slider-img-cnt").removeClass("scroll");
        const vv = $(".img-scrollar-popup").scrollTop(0);
        if ($('.react-transform-element').length > 0) {
            $('.react-transform-element').css({
                transform: 'translate(0px, 0px) scale(1)',

            })
        }
        // $(".img-scrollar-popup").scrollTop(0);

    }

    openImageScrollar(key) {
        $("body").addClass("img-scrollar-active-body");
        $(".img-scrollar-popup").addClass("img-scrollar-active");
        if (null !== key) {
            $(".img-scrollar-popup").scrollTop(0);
            // var getScrollImg = $(".img-scrollar-content").find(".slider-img-cnt[data-image-id=" + key + "]");
            // console.log('target: ' + getScrollImg);
            // console.log('target offset: ' + getScrollImg.offset().top);
            // var scrollTarget = getScrollImg.offset().top - 200;
            // console.log(scrollTarget);
            // $(".img-scrollar-popup").scrollTop($(".img-scrollar-content .slider-img-cnt[data-image-id=" + key + "]").offset().top);
            // console.log($(".img-scrollar-popup").scrollTop($(".img-scrollar-content .slider-img-cnt[data-image-id=" + key + "]").offset().top));
            // $(".img-scrollar-popup").animate({ scrollTop: scrollTarget }, "fast");
            $(".img-scrollar-content .slider-img-cnt[data-image-id=" + key + "]").addClass("scroll").siblings().removeClass("scroll");
            $('.img-scrollar-popup ').animate({
                scrollTop: $(".img-scrollar-content .slider-img-cnt.scroll").offset().top + 600
            },
                'fast');

        }
    }
    openSliderImageScrollar() {
        $("body").addClass("img-scrollar-active-body");
        $(".img-scrollar-popup").addClass("img-scrollar-active");
        if ($('.slide-wrapper .slick-slider').length > 0) {
            const slideactive = $('.slide-wrapper .slick-slider .slick-active.slick-slide').find('.slider-img-cnt').attr('data-image-target');
            $(".img-scrollar-popup").scrollTop(0);
            $(".img-scrollar-content .slider-img-cnt[data-image-id=" + slideactive + "]").addClass("scroll").siblings().removeClass("scroll");
            $('.img-scrollar-popup ').animate({
                scrollTop: $(".img-scrollar-content .slider-img-cnt.scroll").offset().top + 500
            },
                'fast');
        }

    }

    onPinching = (event) => {
        console.log(event);
        alert('test');
    }

    startCountDown() {
        this.countDown()
        this.myInterval = setInterval(() => {
            this.countDown()
        }, 30000)
    }

    copySku() {
        $(document).on("click", ".copysku", function (event) {
            var skuId = $(this).attr("sku-id");
            var cpMessage = $(this).find(".copied-clipboard");
            var positionX = event.pageX - $(this).offset().left;

            navigator.clipboard.writeText(skuId);

            if (0 === cpMessage.length) {
                $(this).append("<span class='copied-clipboard'>Copied! <span class='arrow'></span></span>");

                $(".copied-clipboard").css({
                    'left': positionX
                });
            }
            setTimeout(function () {
                $(".copysku").find(".copied-clipboard").remove();
            }, 1500);
        });
    }

    getInfoColumnHeight() {
        setTimeout(function () {
            let singleImage = $(".productdetail-main-blk .slider-cnt .col-slide-left .single-image-blk").find("img.loaded").length;
            let multipleImage = $(".productdetail-main-blk .slider-cnt .col-slide-left .slider-img-cnt").find("img.loaded").length;

            if (1 === singleImage || 0 < multipleImage) {
                let leftColHeight = $(".productdetail-main-blk .slider-cnt .col-slide-left").outerHeight();
                let rightColHeight = $(".productdetail-main-blk .slider-cnt .col-slide-right").outerHeight();

                if (0 < multipleImage) {
                    if (leftColHeight < rightColHeight) {
                        $(".productdetail-main-blk .slider-cnt").addClass("align-items-start")
                    } else {
                        $(".productdetail-main-blk .slider-cnt").removeClass("align-items-start")
                    }
                }

                if (1 === singleImage) {
                    if (leftColHeight < rightColHeight) {
                        $(".productdetail-main-blk .slider-cnt").addClass("align-items-start")
                    } else {
                        $(".productdetail-main-blk .slider-cnt").removeClass("align-items-start")
                    }
                }

            } else {
                setTimeout(this.getInfoColumnHeight, 1000);
            }
        }, 2000);
    }

    render() {
        if (!this.state.isError) {

            let appleIcon = (
                <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86213 0.783155C4.42637 0.0038441 5.21065 0 5.21065 0C5.21065 0 5.32731 0.732739 4.76688 1.43872C4.16837 2.19245 3.48819 2.06914 3.48819 2.06914C3.48819 2.06914 3.36039 1.47627 3.86213 0.783155Z" fill="#707070" /> <path fillRule="evenodd" clipRule="evenodd" d="M3.56002 2.58218C3.85032 2.58218 4.38888 2.16406 5.09008 2.16406C6.29697 2.16406 6.77177 3.06417 6.77177 3.06417C6.77177 3.06417 5.84318 3.56183 5.84318 4.76931C5.84318 6.13144 7 6.60086 7 6.60086C7 6.60086 6.19131 8.98655 5.0991 8.98655C4.59736 8.98655 4.20748 8.63215 3.67879 8.63215C3.14009 8.63215 2.60562 8.9997 2.25749 8.9997C1.26007 8.99985 0 6.73688 0 4.91775C0 3.12804 1.06654 2.1892 2.06692 2.1892C2.71734 2.18905 3.22205 2.58218 3.56002 2.58218Z" fill="#707070" /> </svg>
            )

            let skyLinkIcon = (
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.7742 3.33556H11.595V3.2107C11.595 1.44482 10.1434 0 8.36918 0H3.22581C1.45161 0 0 1.44482 0 3.2107V9.45373C0 11.2196 1.45161 12.6644 3.22581 12.6644H3.40502V12.7893C3.40502 14.5552 4.85663 16 6.63082 16H11.7742C13.5484 16 15 14.5552 15 12.7893V6.54627C15 4.78038 13.5484 3.33556 11.7742 3.33556ZM3.22581 10.8807C2.43728 10.8807 1.79211 10.2386 1.79211 9.45373V3.2107C1.79211 2.42586 2.43728 1.78372 3.22581 1.78372H8.36918C9.15771 1.78372 9.80287 2.42586 9.80287 3.2107V3.33556H6.63082C4.85663 3.33556 3.40502 4.78038 3.40502 6.54627V10.8807H3.22581ZM9.80287 5.11929V9.45373C9.80287 10.2386 9.15771 10.8807 8.36918 10.8807H5.19713V6.54627C5.19713 5.76143 5.84229 5.11929 6.63082 5.11929H9.80287ZM13.2079 12.7893C13.2079 13.5741 12.5627 14.2163 11.7742 14.2163H6.63082C5.84229 14.2163 5.19713 13.5741 5.19713 12.7893V12.6644H8.36918C10.1434 12.6644 11.595 11.2196 11.595 9.45373V5.11929H11.7742C12.5627 5.11929 13.2079 5.76143 13.2079 6.54627V12.7893Z" fill="#D7D7D7" /> </svg>
            )

            let TwitterIcon = (
                <svg width="24" height="20" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.97721 15C13.1453 15 17.067 9.22846 17.067 4.22492C17.067 4.06266 17.0633 3.8968 17.0558 3.73455C17.8187 3.19849 18.4771 2.53451 19 1.7738C18.2895 2.08095 17.5351 2.28154 16.7627 2.36873C17.576 1.89504 18.185 1.15088 18.4768 0.27422C17.7116 0.714812 16.8748 1.02561 16.0023 1.1933C15.4144 0.586358 14.6371 0.184492 13.7906 0.0498316C12.9441 -0.0848291 12.0754 0.0552155 11.319 0.448313C10.5626 0.841411 9.96059 1.46567 9.60596 2.22457C9.25133 2.98347 9.16388 3.83474 9.35713 4.64678C7.80781 4.57123 6.29212 4.18018 4.90832 3.49897C3.52452 2.81776 2.30351 1.8616 1.32443 0.692474C0.826816 1.52608 0.674545 2.51251 0.898566 3.45128C1.12259 4.39005 1.70609 5.21072 2.53049 5.74649C1.91158 5.7274 1.30623 5.5655 0.764453 5.27416V5.32103C0.763899 6.19584 1.07516 7.04383 1.64533 7.72087C2.2155 8.39791 3.00938 8.86221 3.89203 9.03483C3.31871 9.18725 2.71699 9.20945 2.13342 9.09974C2.38249 9.85207 2.86708 10.5101 3.51957 10.982C4.17206 11.4538 4.95989 11.716 5.77311 11.7318C4.3925 12.7856 2.68705 13.3571 0.931445 13.3544C0.620102 13.3539 0.309066 13.3354 0 13.2989C1.78351 14.4106 3.85821 15.0011 5.97721 15Z" fill="#707070" /> </svg>
            )

            var settings = {
                nav: true,
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 2000,
                arrows: true,
                pagination: true,
                initialSlide: 0,
                beforeChange: (current, next) => {
                    var sizeactive = $(".slick-slider .slick-slide[data-index=" + next + "]").find(".slider-img-cnt").attr("data-sizerun");
                    $('.sizerun-list li').each(function () {
                        if ($(this).text() == sizeactive) {
                            $(this).addClass("size-active").siblings().removeClass("size-active");
                        }
                    });

                }
            }

            let productDetail = this.state.productDetail;

            const getSlide = (item, key, index) => {
                return <div key={key} data-image-target={`slide-image-${key}`} data-sizerun={productDetail.images_captions.length > 0 ? productDetail.images_captions[key] : ''} className={key > 3 && this.state.width >= 1025 ? "slider-img-cnt hide-slide-blk" : "slider-img-cnt"}>
                    <div className="slider-img-cnt-blk">
                        {this.state.width >= 1025 && index.length >= 4 ?

                            <img src={item} className="d-block w-100" onClick={() => this.openImageScrollar(`slide-image-${key}`)} alt=".." />
                            :
                            <img src={item} className="d-block w-100" alt=".." />
                        }
                        {/* {index.length == 0 ? <span>HR Image Unavailable</span> : ''} */}
                    </div>
                    {/* {productDetail.images_captions.length > 0 || productDetail.images_number.length > 0 ?
                        <div className="slider-caption">
                            <span>{productDetail.images_captions.length > 0 ? productDetail.images_captions[key] : ''} <span className="slide-item-number">{productDetail.images_number.length > 0 ? productDetail.images_number[key] + '/' + productDetail.images_number.length : ''}</span> </span>
                        </div>
                        : ''} */}
                </div>
            }

            const getScrollarImg = (item, key) => {
                return (
                    <div key={key} data-image-id={`slide-image-${key}`} className="slider-img-cnt">
                        {this.state.width >= 1025 ?
                            <div className="slider-img-cnt-blk">
                                <img src={item} className="d-block" alt=".." />
                            </div>
                            :
                            // <TransformWrapper
                            //     defaultScale={1}
                            //     defaultPositionX={0}
                            //     defaultPositionY={0}
                            //     onPinching={this.handlePinch}
                            //     options={{
                            //         limitToBounds: true,
                            //         limitToWrapper: true,
                            //     }}
                            //    pinch ={{
                            //     disabled:false
                            //    }}
                            //     wheel={{
                            //         step: 10,
                            //         wheelEnabled: true
                            //     }}
                            // >
                            //     {({ zoomIn, zoomOut, resetTransform, onPinching, pinch, ...rest }) => (
                            //         <TransformComponent>
                            //             <div className="slider-img-cnt-blk" onTouchStart={zoomIn} onTouchEnd={zoomOut}>
                            //                 <img src={item} className="d-block" alt=".." />
                            //             </div>
                            //         </TransformComponent>
                            //     )}

                            // </TransformWrapper>
                            <PinchToZoom>
                                <img src={item} className="d-block" alt=".." />
                            </PinchToZoom>
                        }
                    </div>
                )
            }

            const getSizerun = (item, key) => {
                if (this.state.productDetail.images_captions[key] !== undefined) {
                    return <li className={this.state.productDetail.images_captions[key] == item.size_run ? "size-active" : ""}>{item.size_run}</li>
                }
            }
            let viewIcon = (
                <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.84741 10.3129C3.65883 10.3129 0.402612 5.83857 0.261345 5.65213L0 5.27927L0.268408 4.90641C0.402612 4.71332 3.65883 0.245605 7.85447 0.245605C12.043 0.245605 15.2993 4.71997 15.4405 4.90641L15.7089 5.27927L15.4405 5.65213C15.2993 5.84522 12.043 10.3129 7.84741 10.3129ZM1.73053 5.27927C2.55694 6.28467 5.03618 8.98128 7.84741 8.98128C10.6586 8.98128 13.1379 6.28467 13.9643 5.27927C13.1379 4.27387 10.6586 1.57726 7.84741 1.57726C5.03618 1.57726 2.55694 4.27387 1.73053 5.27927Z" fill="#707070" /> <path d="M7.84778 8.5417C5.94066 8.5417 4.38672 7.07688 4.38672 5.27915C4.38672 3.48142 5.94066 2.0166 7.84778 2.0166C9.7549 2.0166 11.3088 3.48142 11.3088 5.27915C11.3088 7.07688 9.76196 8.5417 7.84778 8.5417ZM7.84778 3.34825C6.71764 3.34825 5.7994 4.21383 5.7994 5.27915C5.7994 6.34447 6.71764 7.21005 7.84778 7.21005C8.97792 7.21005 9.89617 6.34447 9.89617 5.27915C9.89617 4.21383 8.97792 3.34825 7.84778 3.34825Z" fill="#707070" /> </svg>
            )

            let warningIcon = (
                <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.9167 0L0 18.9482H23.8333L11.9167 0ZM10.2917 6.31608H13.5417V11.1746H10.2917V6.31608ZM11.9167 17.0048C10.725 17.0048 9.75 16.1303 9.75 15.0614C9.75 13.9925 10.725 13.118 11.9167 13.118C13.1083 13.118 14.0833 13.9925 14.0833 15.0614C14.0833 16.1303 13.1083 17.0048 11.9167 17.0048Z" fill="black" /> </svg>
            )

            const slides = productDetail.images.map((item, key, index) =>
                getSlide(item, key, index)
            );

            // slides.push(slides.shift());
            // slides.push();

            const scrollarImages = productDetail.images.map((item, key) =>
                getScrollarImg(item, key)
            );

            // scrollarImages.push(scrollarImages.shift());
            scrollarImages.push();

            const sizesrun = productDetail.sizes.map((item, key) =>
                getSizerun(item, key)
            );
            // sizesrun.push(sizesrun.shift());

            const sizeRunTable = productDetail.sizes.map((item, key) =>
                <tr key={key}>
                    <td>{item.size_run}</td>
                    <td>{item.style_id} {item.style_id !== 'N/A' ? <i className="copysku" sku-id={item.style_id}>{skyLinkIcon}</i> : ''}</td>
                    <td>${item.price}</td>
                </tr>
            );

            const releasesDates = productDetail.releases.map((item, key) =>
                <div key={key} className="release-date"><span>{item.date}  <span className="rel-time">{item.time} </span></span></div>
            );
            const getBUttonColor = (color) => {
                return { backgroundColor: color }
            }
            const listStores = (stores, keys) => {
                return <tr key={keys}><td className="retailer-col"><a href={stores.link} onClick={() => { this.addKfRetailarsCount(stores.id); COMMONCONST.GAEvent({ category: 'StockistsStore', action: 'Click From Product Page', label: stores.name + ',' + stores.link }) }} target="_blank" rel="noopener noreferrer"><span>{stores.name}{/*<span className="external-link"><img src={externalLinkIcon} alt="..."></img></span>*/}</span></a></td>
                    <td className="time-col"><span>{stores.time ? stores.time : <span className="">N/A</span>}</span></td>
                    <td className="ships-col"><span>{stores.shipping}</span></td>
                    <td className="release-type"><span>{stores.release_type}</span></td>
                    {this.checkReleaseDate(stores.release_date ? stores.release_date : stores.date + ' 00:00:00', stores.default_time_zone) ?
                        <td className="btn-col"><span className="tbl-btn-wrapper"><a href={stores.link} onClick={() => { this.addKfRetailarsCount(stores.id); COMMONCONST.GAEvent({ category: 'StockistsStore', action: 'Click From Product Page', label: stores.name + ',' + stores.link }) }} target="_blank" rel="noopener noreferrer" style={getBUttonColor(stores.before_release_color)} className="btn">{stores.before_release_text}</a></span></td>
                        :
                        <td className="btn-col"><span className="tbl-btn-wrapper"><a href={stores.link} onClick={() => { this.addKfRetailarsCount(stores.id); COMMONCONST.GAEvent({ category: 'StockistsStore', action: 'Click From Product Page', label: stores.name + ',' + stores.link }) }} target="_blank" rel="noopener noreferrer" style={getBUttonColor(stores.after_release_color)} className="btn">{stores.after_release_text}</a></span></td>
                    }
                    <td className="reminder-col">
                        {stores.release_type === 'After Market' || stores.release_type === 'In Store Only' ?
                            <span className="text-center">-</span>
                            :
                            <span>
                                {this.checkReleaseDate(stores.release_date ? stores.release_date : stores.date + ' 00:00:00', stores.default_time_zone) ?
                                    <>
                                        <span className="google-cal" onClick={() => this.googleCalendar(productDetail.full_name, stores.release_date, productDetail.product_url, stores.default_time_zone)}>Google <i>Cal</i></span>
                                        <span className="ical" onClick={() => this.iCalendar(productDetail.full_name, stores.release_date, productDetail.product_url, stores.default_time_zone)}><i>{appleIcon}</i> iCal</span>
                                    </>

                                    : <span className="text-center">-</span>}
                            </span>
                        }
                    </td>
                    <td className="res-arrow d-xl-none d-lg-none d-md-none d-sm-inline-flex d-inline-flex"><i className="fa fa-caret-down"></i></td>
                </tr>
            }

            const addoldRegionHtml = (k, data, key) => {
                return <div key={k} className="prod-counrywise-tbl">

                    <h4 className="cntry-title">{key} <span className="cntry-date">({data.date})</span></h4>

                    <table className="prod-table">
                        <thead>
                            <tr>
                                <th className="retailer-col">Retailer</th>
                                <th className="time-col">Time</th>
                                <th className="ships-col">Shipping</th>
                                <th className="release-type">Release type</th>
                                <th className="btn-col">Status</th>
                                <th className="reminder-col">Reminder</th>
                                <th className="res-arrow d-xl-none d-lg-none d-md-none d-sm-inline-flex d-inline-flex"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.map((values, keys) =>
                                listStores(values, keys)
                            )}
                        </tbody>
                    </table>

                </div>
            }

            const singleRegionBlock = (value, key, main) => {
                if (value.product_stockists.length == 0) {
                    return '';
                }
                return <div key={key} className="prod-counrywise-tbl">

                    <h4 className="cntry-title">{value.region} <span className="cntry-date">{value.release_date_show}</span>
                        {/* {main.is_restock[value.region] > 1 ?
                            <span>({value.is_rerelease == 0 ? 'Original Release' : 'Restock'})</span>
                            : ''} */}
                    </h4>

                    <table className="prod-table">
                        <thead>
                            <tr>
                                <th className="retailer-col">Retailer</th>
                                <th className="time-col">Time</th>
                                <th className="ships-col">Shipping</th>
                                <th className="release-type">Release type</th>
                                <th className="btn-col">Status</th>
                                <th className="reminder-col">Reminder</th>
                                <th className="res-arrow d-xl-none d-lg-none d-md-none d-sm-inline-flex d-inline-flex"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {value.product_stockists.map((values, keys) =>
                                listStores(values, keys)
                            )}
                        </tbody>
                    </table>

                </div>
            }

            const updateMessage = productDetail.product_update_messages.map((value, key) =>
                <div className="kf-warning">
                    <i className="icon-warning">{warningIcon}</i> <b>UPDATE ({value.update_date}):</b> <span dangerouslySetInnerHTML={{ __html: value.message }}></span>
                </div>
            );

            const regions = productDetail.sizes.map((value, key) =>
                <>
                    {value.product_regions_rereleases.length > 0 ?
                        <div className="prod-cat-row">
                            {value.product_regions_rereleases.length > 0 && value.is_same_as_parent_region != 1 && value.is_retailer == 1 ?
                                <div className="prod-row-header">
                                    <div className="prod-cat-title">
                                        <strong>{value.size_run} </strong>
                                        <div className="prod-cat-subtitle">
                                            <span>SKU: </span> <strong>{value.style_id}, </strong>
                                            <span>Retail Price: </span> <strong>${value.price} </strong>
                                        </div>
                                    </div>
                                    <div className="prod-head-info">
                                        <div className="prod-head-collout">
                                            <i>{TwitterIcon}</i> Make sure to follow <a href={COMMONCONST.TWITTER} target="_blank" rel="noopener noreferrer">@kicksfinder</a> for live tweets during the release date
                                        </div>
                                        {productDetail.is_time_drop_show == 1 ?
                                            <div className="timezone-select">
                                                <select className="cst-select"
                                                    value={this.state.userTimeZone}
                                                    onChange={this.updateTimezone}>
                                                    {/* <option>(GMT - 5:00) Eastern Time</option> */}
                                                    {this.state.timeZone &&
                                                        Object.keys(this.state.timeZone).map(key => (
                                                            <option key={key}
                                                                value={key}
                                                            >
                                                                {this.state.timeZone[key]}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                                : ''}
                            {value.is_retailer == 1 ?
                                <div className="prod-cat-body">
                                    {value.product_regions_rereleases.map((values, keys) =>
                                        singleRegionBlock(values, keys, value)
                                    )}
                                </div>
                                : ''}
                        </div>
                        : ''}
                </>
            );

            let fireIcon = (
                <svg version="1.1" id="Layer_1" x="0px" y="0px" width="18.028px" height="24px" viewBox="0 0 18.028 24" enableBackground="new 0 0 18.028 24">
                    <g>
                        <defs>
                            <rect id="SVGID_1_" y="0" width="18.027" height="24" />
                        </defs>
                        <clipPath id="SVGID_2_">
                            <use xlinkHref="#SVGID_1_" overflow="visible" />
                        </clipPath>
                        <path fill="none" stroke="#B4B4B4" strokeWidth="2" strokeMiterlimit="10" d="M15.139,11.8
                        c0.163,4.954-3.336,8.691-7.18,8.691C4.116,20.492,1,17.376,1,13.532C1,8.778,6.11,4.801,8.187,2.359
                        c0.19,1.43,0.229,5.68,1.517,7.691C10.775,11.726,13.326,12.243,15.139,11.8z"/>
                    </g>
                </svg>
            )

            let imgScrollarClose = (
                <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="34" cy="34" r="34" fill="black" fillOpacity="0.6" /> <path d="M47 23.82L44.18 21L34 31.18L23.82 21L21 23.82L31.18 34L21 44.18L23.82 47L34 36.82L44.18 47L47 44.18L36.82 34L47 23.82Z" fill="white" /> </svg>
            )

            let zoomImgIcon = (
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.13683 14.5791L11.2225 12.4934L8.38574 9.65362L9.65452 8.38484L12.4943 11.2246L14.58 9.13892V14.5791H9.13683Z" fill="black" fillOpacity="0.3" /> <path d="M4.92549 8.38484L6.19426 9.65362L3.35448 12.4934L5.44018 14.5791H0V9.13892L2.0857 11.2246L4.92549 8.38484Z" fill="black" fillOpacity="0.3" /> <path d="M9.65452 6.19434L8.38574 4.92556L11.2225 2.08577L9.13683 7.39098e-05H14.58V5.44324L12.4943 3.35754L9.65452 6.19434Z" fill="black" fillOpacity="0.3" /> <path d="M4.92549 6.19434L2.0857 3.35754L0 5.44324V7.39098e-05H5.44018L3.35448 2.08577L6.19426 4.92556L4.92549 6.19434Z" fill="black" fillOpacity="0.3" /> </svg>
            )

            if (productDetail.meta_title) {
                document.title = productDetail.meta_title;
                $('#og-title').attr('content', productDetail.meta_title);
            }
            if (productDetail.meta_description) {
                $('#meta-description').attr('content', productDetail.meta_description);
                $('#og-description').attr('content', productDetail.meta_description);
            }

            return (
                <div className='App'>
                    <div className="productdetail-main-blk">
                        <div className="container">
                            <div className="productdetail-cnt">
                                <div className="img-scrollar-popup">
                                    <div className="img-scrollar-wrap">
                                        <span className="close-img-scrollar" onClick={this.closeImageScrollar}>{imgScrollarClose}</span>
                                        <div className="img-scrollar-content">
                                            {scrollarImages}
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-cnt">
                                    <div className="col-slide-left">
                                        {this.state.width >= 1025 ? <>{this.state.productDetail.images.length > 0 ?
                                            <div className={slides.length == 1 ? "slide-single-wrapper" : "slide-wrapper"}>{slides.length <= 3 && slides.length > 1 ?
                                                <Fragment>
                                                    <Slider {...settings}>{slides}</Slider>
                                                    <span className="zoom-img-icon" onClick={() => this.openSliderImageScrollar()}>{zoomImgIcon}</span>
                                                </Fragment> : slides
                                            }</div> : ''}  </> : <>
                                            {this.state.productDetail.images.length > 0 ?
                                                <Fragment>

                                                    <Slider {...settings}>{slides}</Slider>
                                                    {slides.length != 1 ? <span className="zoom-img-icon" onClick={() => this.openImageScrollar(null)}>{zoomImgIcon}</span> : ''}
                                                    <ul className="sizerun-list">
                                                        {sizesrun}
                                                    </ul>
                                                </Fragment>
                                                : ''}
                                        </>

                                        }

                                        {this.state.productDetail.images.length == 0 ?
                                            <div className="single-image-blk">
                                                <div className="d-block w-100">
                                                    <img src={this.state.productDetail.product_thumbnail} className="d-block loaded" />
                                                    <span>HD Image Unavailable</span>
                                                </div>

                                            </div>
                                            : ''}
                                    </div>
                                    <div className="col-slide-right">
                                        {productDetail.current_week_position && parseInt(productDetail.current_week_position) <= 100 ?
                                            <>
                                                {productDetail.current_week_position && !productDetail.previous_week_position ?
                                                    <div className="prod-rank-wrp">
                                                        <div className="prod-rank-item">
                                                            <span className="prod-rank-status">Top {productDetail.current_week_position}</span>
                                                            <span className="prod-rank-new">new</span>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                {productDetail.current_week_position && productDetail.previous_week_position && parseInt(productDetail.current_week_position) < parseInt(productDetail.previous_week_position) ?
                                                    <div className="prod-rank-wrp">
                                                        {parseInt(productDetail.previous_week_position) - parseInt(productDetail.current_week_position) <= 999 ?
                                                            <div className="prod-rank-item">
                                                                <span className="prod-rank-status">Top {productDetail.current_week_position}</span>
                                                                <span className="prod-rank-num prod-rank-positive">
                                                                    <i className="fa fa-caret-up"></i>{parseInt(productDetail.previous_week_position) - parseInt(productDetail.current_week_position)}
                                                                </span>
                                                            </div>
                                                            : <div className="prod-rank-item">
                                                                <span className="prod-rank-status">Top {productDetail.current_week_position}</span>
                                                                <span className="prod-rank-new">new</span>
                                                            </div>}
                                                    </div>
                                                    : ''}
                                                {productDetail.current_week_position && productDetail.previous_week_position && parseInt(productDetail.current_week_position) > parseInt(productDetail.previous_week_position) ?
                                                    <div className="prod-rank-wrp">
                                                        {parseInt(productDetail.current_week_position) - parseInt(productDetail.previous_week_position) <= 999 ?
                                                            <div className="prod-rank-item">
                                                                <span className="prod-rank-status">Top {productDetail.current_week_position}</span>
                                                                <span className="prod-rank-num prod-rank-negative">
                                                                    <i className="fa fa-caret-up"></i>{parseInt(productDetail.current_week_position) - parseInt(productDetail.previous_week_position)}
                                                                </span>
                                                            </div>
                                                            : <div className="prod-rank-item">
                                                                <span className="prod-rank-status">Top {productDetail.current_week_position}</span>
                                                                <span className="prod-rank-new">new</span>
                                                            </div>}
                                                    </div>
                                                    : ''}
                                                {productDetail.current_week_position && productDetail.previous_week_position && parseInt(productDetail.current_week_position) == parseInt(productDetail.previous_week_position) ?
                                                    <div className="prod-rank-wrp">
                                                        <div className="prod-rank-item">
                                                            <span className="prod-rank-status">Top {productDetail.current_week_position}</span>
                                                            <span className="prod-rank-num "> -
                                                            </span>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </>
                                            : ''}
                                        <div className="slide-title">
                                            <h4>
                                                {productDetail.full_name}
                                                {productDetail.product_nickname && (
                                                    <span className="title-nickname">“{productDetail.product_nickname}”</span>
                                                )}
                                                {!productDetail.product_nickname && productDetail.color_text && productDetail.color_text != 'N/A' && (
                                                    <span className="color-text">{productDetail.color_text}</span>
                                                )}
                                            </h4>
                                        </div>
                                        {/* <div className="release-day">
                                            <span>{this.state.showtimer}</span>
                                        </div> */}
                                        <div className="prod-info-count">
                                            <div className="prod-count-release">
                                                <div className="prod-release-wrap">
                                                    <div className="release-date">
                                                        <span className="release-date-txt">{productDetail.pre_date_message} </span>
                                                        <span className="current-display-release">{productDetail.current_display_release}{productDetail.current_display_release_week_day ? ', ' : ''}</span>
                                                        <span className="release-weekday">{productDetail.current_display_release_week_day}</span>
                                                        <span className="rel-time">{productDetail.current_display_release_time} </span>
                                                    </div>
                                                </div>
                                                <ul className="info-count">
                                                    <li title="Views">
                                                        <i className="eye-icon">{viewIcon}</i>
                                                        <span className="number">{productDetail.views_count}</span>
                                                    </li>
                                                    <li title="Likes" className={productDetail.is_liked === 1 ? 'active' : ''}>
                                                        <i onClick={() => this.like(productDetail.id)} className="fire">{fireIcon}</i>
                                                        <span id={productDetail.id + "-like"} className="number">{productDetail.likes_counts}</span>
                                                    </li>
                                                    <li title="Watchlist" className={productDetail.is_watchlisted === 1 ? 'active' : ''}>
                                                        <i onClick={() => this.watchlist(productDetail.id)} className="fa fa-bookmark-o"></i>
                                                        <span id={productDetail.id + "-watchlist"} className="number">{productDetail.watch_list_count}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="prod-sku-table">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Size Run</th>
                                                            <th>SKU</th>
                                                            <th>Retail Price</th>
                                                        </tr>
                                                        {sizeRunTable}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {/* {productDetail.releases.length > 0 ?
                                                <div className="prod-restock-date">
                                                    <span className="release-date-txt">Release Date</span>
                                                    {releasesDates}
                                                </div>
                                                : ''} */}
                                            {/* <div className="prodcolor-blk">
                                                <p>Color</p>
                                                <p><b>{productDetail.color_text}</b></p>
                                            </div> */}

                                            {/* <div className="moreinfo-blk">
                                                <span>More info @&nbsp;
                                                    {productDetail.product_url ?
                                                        <a href={productDetail.product_url} target="_blank" rel="noopener noreferrer"><img src={SneakerLogo} alt="sneakernews-logo" /></a>
                                                        : <a href="#" onClick={e => e.preventDefault()} rel="noopener noreferrer"><img src={SneakerLogo} alt="sneakernews-logo" /></a>
                                                    }

                                                </span>
                                            </div> */}
                                            {/* {productDetail.credit ?
                                                <div className="moreinfo-blk">
                                                    <span>Images:&nbsp;
                                                        <a href={productDetail.credit_url} target="_blank" rel="noopener noreferrer" className="prod-img-link">{productDetail.credit}</a>
                                                    </span>
                                                </div>
                                                : ''} */}
                                            {(productDetail.product_nickname || productDetail.product_model_name) && productDetail.product_url && 
                                                <div className="post-sn-disclamier-blk">
                                                    For more info visit the <a href={productDetail.product_url} target="_blank" rel="noopener noreferrer"><strong>{productDetail.product_model_name}{productDetail.product_nickname && (<> “{productDetail.product_nickname}”</>)}</strong></a> page on Sneaker News.
                                                </div>
                                            }
                                            
                                            {/* button functionality is pending */}
                                            <div className="button-row">
                                                <button onClick={() => this.like(productDetail.id)} title="Likes" className={productDetail.is_liked === 1 ? 'btn-primary-gray-like btn-primary-gray active' : 'btn-primary-gray btn-primary-gray-like'}>
                                                    <i className="fa fa-spinner loadicon"></i>
                                                    <i className="fire">{fireIcon}</i>
                                                    <span className="btn-primary-gray-like-text">{productDetail.is_liked === 1 ? 'Liked' : 'Like'}</span>
                                                </button>
                                                <button onClick={() => this.watchlist(productDetail.id)} title="Watchlist" className={productDetail.is_watchlisted === 1 ? 'btn-primary-gray-watchlist btn-primary-gray active' : 'btn-primary-gray btn-primary-gray-watchlist'}>
                                                    <i className="fa fa-spinner loadicon"></i>
                                                    <i className="fa fa-bookmark-o"></i>
                                                    <span className="btn-primary-gray-watchlist-text">{productDetail.is_watchlisted === 1 ? 'Added to Watchlist' : 'Add to Watchlist'}</span>
                                                </button>
                                            </div>
                                            <div className="post-disclamier-blk">
                                                <span>This post contains references to products from one or more of our advertisers. We may receive compensation when you click on links to those products. </span>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                                {/* <div className="kf-warning">
                                    <i className="icon-warning">{warningIcon}</i> <b>UPDATE (01/26/2021):</b> Official images of the Nike Dunk High have been added ahead.
                                </div>

                                <div className="kf-warning">
                                    <i className="icon-warning">{warningIcon}</i> <b>UPDATE (01/26/2021):</b> The adidas Yeezy Boost 350 V2 “Sand Taupe” has been delayed until December 19th as opposed to December 18th as originally reported.
                                </div> */}
                                {/* {productDetail.product_update_messages.length > 0 ?
                                    productDetail.product_update_messages.map((value, key) =>
                                        updateMessage(value)
                                    )
                                    : ''} */}
                                {updateMessage}

                                <div className="prod-below-cnt">
                                    {regions}

                                </div>
                                {productDetail.is_retailer == 0 ?
                                    <div className="prod-below-cnt">
                                        <div className="prod-cat-row">

                                            <div className="prod-cat-body">
                                                <div className="retailer-not-found">Retailer information unavailable yet. Please check back for any updates.</div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {this.state.width <= 767 ?
                                    <div className="popular-prod-blk">
                                        <PopularList />
                                    </div>
                                    : ''}
                                <div className="prod-footer-cnt">
                                    <div className="you-may-slider">
                                        {productDetail.you_may_like !== '' ?
                                            <YouMayAlsoLike title="You May Also Like" product_id={productDetail.id} releate_product={productDetail.you_may_like}></YouMayAlsoLike>
                                            : ''}
                                    </div>
                                    <div className="sneakernews-blk">
                                        {productDetail.simillar !== '' ? <LatestNews title="Related News" related_data={productDetail.simillar}></LatestNews> : ''}
                                        {this.state.width <= 767 ?
                                            <>
                                                {productDetail.brand_name == 'Nike' || productDetail.brand_name == 'Jordan Brand' ?
                                                    <>
                                                        <div className="loadmore-btn">
                                                            <Link to="/#/nike">
                                                                <button className="load-more">Nike Release Dates</button>
                                                            </Link>
                                                        </div>
                                                        <div className="loadmore-btn">
                                                            <Link to="/#/Jordan">
                                                                <button className="load-more">Jordan Release Dates</button>
                                                            </Link>
                                                        </div>
                                                    </>
                                                    : productDetail.brand_name == 'adidas' || productDetail.brand_name == 'Yeezy' ?
                                                        <>
                                                            <div className="loadmore-btn">
                                                                <Link to="/#/adidas">
                                                                    <button className="load-more">adidas Release Dates</button>
                                                                </Link>
                                                            </div>
                                                            <div className="loadmore-btn">
                                                                <Link to="/#/Yeezy">
                                                                    <button className="load-more">Yeezy Release Dates</button>
                                                                </Link>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="loadmore-btn">
                                                                <Link to="/#/Jordan">
                                                                    <button className="load-more">Jordan Release Dates</button>
                                                                </Link>
                                                            </div>
                                                            <div className="loadmore-btn">
                                                                <Link to="/#/Yeezy">
                                                                    <button className="load-more">Yeezy Release Dates</button>
                                                                </Link>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                            : ''}
                                    </div>
                                    {/* <div className="ad-cnt-blk">
                                        <div className="ad-blk"></div>
                                    </div> */}
                                    {(productDetail.id && this.state.width > 767) ?
                                        <div className="release-soon-blk">
                                            <div>
                                                <h4 className="main-title">Releasing Soon</h4>
                                            </div>
                                            <div>
                                                <ReleaseSoon product_id={productDetail.id} />
                                            </div>

                                        </div>
                                        : ''}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <NotFound404></NotFound404>
            )
        }
    }
}

export default ProductDetail
